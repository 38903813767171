import {call, put, takeLatest} from "redux-saga/effects";
import {GetResource, PutResource} from "../../utils/HttpUtil";
import {
    REQUEST_CURRENCY_LIST,
    REQUEST_REMOVE_CURRENCY,
    SET_CURRENCY_LIST,
    SET_EDIT_CLIENT,
    SET_ERROR_MSG, SET_REMOVE_CURRENCY
} from "@actions/actionTypes";
import {onError, safe} from "./handlerError";


function* getCurrencyList() {
    const currencyList =  yield call(GetResource, `/client/currencies`);
    yield put({
        type: SET_CURRENCY_LIST,
        currencyList: currencyList && currencyList.items ? currencyList.items : []
    });
}

function* removeCurrency({body}) {
    const client =  yield call(PutResource, `/Client/${body.id}`,body);
    yield put({
        type: SET_REMOVE_CURRENCY,
        client: client.data
    });
    yield put({type: SET_ERROR_MSG, data: {type: 'done', messages: "success"}})
}

function* CurrencySaga() {
    yield takeLatest(REQUEST_CURRENCY_LIST, safe(onError, getCurrencyList));
    yield takeLatest(REQUEST_REMOVE_CURRENCY, safe(onError, removeCurrency));
}

export default CurrencySaga;