import {REQUEST_CURRENCY_LIST, REQUEST_REMOVE_CURRENCY} from "@actions/actionTypes";

export function getCurrencyList() {
    return dispatch => {
        dispatch({
            type: REQUEST_CURRENCY_LIST
        });
    }
}

export function removeCurrency(body) {
    return dispatch => {
        dispatch({
            type: REQUEST_REMOVE_CURRENCY,
            body
        })
    }
}