import React, {useEffect} from 'react'
import {connect} from "react-redux";
import { Link, useLocation, useHistory } from 'react-router-dom';
import {FormattedMessage} from "react-intl";
import {loginUser, setUser} from '@actions/userActions';
import {CompleteLogin, getPath} from "@services/AuthService";


function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
}

function CallbackLogin({ user, setUser}) {

    // let query = useQuery();
    let history = useHistory();

    useEffect(() => {
        CompleteLogin().then((user) => {
            if (user) {
                setUser(user);
                history.push(user.state);
            }
        });
    }, [setUser]);

    return (
        <div>
        </div>
    )
}

function mapStateToProps(state) {
    return {
        user: state.user
    }
}

const mapDispatchToProps = {
    loginUser,
    setUser
}

export default connect(mapStateToProps, mapDispatchToProps)(CallbackLogin);