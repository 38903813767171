import initialStore from "../initialStore";
import {
    SET_CURRENCY_LIST,
} from "@actions/actionTypes";

export default function currencyList(state = initialStore.emptyArr, action) {
    switch (action.type) {
        case SET_CURRENCY_LIST:
            return  action.currencyList
        default:
            return state;
    }
}
