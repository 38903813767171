import { call, put, takeLatest } from 'redux-saga/effects';
import {
    REQUEST_PLAYERS_BY_IP,
    SET_PLAYERS_BY_IP
} from "@actions/actionTypes";
import {
    GetResource,
} from '../../utils/HttpUtil';
import {onError, safe} from "./handlerError";


function* fetchPlayerByIp({ip}) {
    const players = yield call(GetResource, `/Bet/players?ip=${ip}`);
    yield put({ type: SET_PLAYERS_BY_IP, players: players.items });
}

function* playersSaga() {
    yield takeLatest(REQUEST_PLAYERS_BY_IP, safe(onError, fetchPlayerByIp));
}
 
export default playersSaga;