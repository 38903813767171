import {
    REQUEST_ACTIVE_BET_LIST,
    CLEAR_ACTIVE_BET_LIST
} from "@actions/actionTypes";

export function getActiveBetList(params) {
    return dispatch => {
        dispatch({
            type: REQUEST_ACTIVE_BET_LIST,
            params
        })
    }
}

export function clearActiveBetList() {
    return dispatch => {
        dispatch({
            type: CLEAR_ACTIVE_BET_LIST
        })
    }
}

